import React, { useState } from 'react';
import { Link, ScrollRestoration } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { Footer } from './template';

export function FaqPage() {
  return (
    <div className='max-w-3xl mx-auto p-8'>
      <Helmet>
        <title>zuper - Συχνές ερωτήσεις</title>
        <meta name="description" content='Συχνές ερωτήσεις σχετικά με το zuper' />
      </Helmet>

      <div className='mx-auto py-4 text-center text-4xl text-lime-600 font-extrabold tracking-wide'>
        <h1><Link to="/">zuper</Link></h1>
      </div>

      <h2 className='text-2xl pt-2 pb-4'>Συχνές ερωτήσεις</h2>

      <dl>
        <dt>Τι είναι το zuper?</dt>
        <dd>To zuper είναι ένα site που επιτρέπει την αναζήτηση ειδών super market σε πολλαπλά καταστήματα ταυτόχρονα. Αυτό επιτρέπει την εύκολη σύγκριση τιμών και βοηθά τους καταναλωτές να κάνουν την καλύτερη επιλογή.</dd>

        <dt>Οι τιμές αφορούν μόνο τους ιστότοπους των super market ή και τα φυσικά καταστήματα;</dt>
        <dd>Οι τιμές προέρχονται από τους ιστότοπους των super market, αλλά σε γενικές γραμμές τα φυσικά καταστήματα ακολουθούν τις online τιμές.</dd>

        <dt>Πόσο συχνά ανανεώνονται οι τιμές;</dt>
        <dd>Ανανεώνονται δύο φορές τη μέρα.</dd>

        <dt>Οι τιμές είναι πάντα σωστές;</dt>
        <dd>Κάνουμε ότι μπορούμε για να εξασφαλίσουμε την ορθότητα των τιμών αλλά &mdash; όπως με όλο το λογισμικό &mdash; πάντα υπάρχει πιθανότητα λάθους. Για να είστε απόλυτα σίγουροι, πρέπει να διασταυρώνετε τις τιμές με αυτές μου κοινοποιούν τα ίδια τα καταστήματα.</dd>

        <dt>Τι είναι το εικονικό καλάθι του zuper;</dt>
        <dd>Το εικονικό καλάθι σου επιτρέπει να δημιουργήσεις μια λίστα προϊόντων και να δεις τις συνολικές τιμές τους ανά super market.</dd>

        <dt>Ποιές τιμές δείχνει το εικονικό καλάθι;</dt>
        <dd>Το καλάθι σώζεται στην συσκευή σου και κάθε φορά που το επισκέπτεσαι οι τιμές ανανεώνονται στις πιο πρόσφατες.</dd>

        <dt>Αν προσθέσω προϊόντα στο εικονικό καλάθι, μπορώ να τα αγοράσω μέσα από το zuper;</dt>
        <dd>Όχι, το καλάθι δεν παρέχει δυνατότητα αγορών.</dd>

        <dt>Έχω μια ιδέα που θα έκανε το zuper καλύτερο!</dt>
        <dd>Το zuper εξελίσσεται συνεχώς και θα προστεθεί έξτρα λειτουργικότητα στο άμεσο μέλλον. Θα χαρούμε να ακούσουμε τις ιδέες σας μέσω email στο <a href='mailto:feedback@zuper-market.gr' className='underline'>feedback@zuper-market.gr</a></dd>

      </dl>

      <div className="pt-32">
        <Footer />
      </div>
      <ScrollRestoration />
    </div>
  );
}
