import React, { createContext, useEffect, useReducer } from 'react';
import { Map, fromJS } from 'immutable';

export const BasketContext = createContext(null);

function inc(x: number) {
  return x + 1;
}

function dec(x: number) {
  return x - 1;
}

function basketReducer(state, action) {
  const products = state.get('products') || Map({});
  const product = action?.payload;
  const id = product?.id;

  switch (action.type) {
    case 'add-one-item':
      if (products && products.has(id)) {
        return state
          .updateIn(['products', id, 'zuper-basket-quantity'], inc);
      } else {
        return state
          .setIn(['products', id, 'id'], product.id)
          .setIn(['products', id, 'title'], product.title)
          .setIn(['products', id, 'market'], product.market)
          .setIn(['products', id, 'slug'], product.slug)
          .setIn(['products', id, 'photo-filename'], product['photo-filename'])
          .setIn(['products', id, 'zuper-basket-quantity'], 1);
      }

    case 'remove-one-item':
      if (state.getIn(['products', id, 'zuper-basket-quantity']) === 1) {
        return state
          .deleteIn(['products', id]);
      } else {
        return state
          .updateIn(['products', id, 'zuper-basket-quantity'], dec);
      }

    case 'remove-product':
      return state
        .deleteIn(['products', action?.payload]);

    case 'empty':
      return state.setIn(['products'], Map({}));

    default:
      return state;
  }
}

function getFromLocalStorage(k) {
  return fromJS(JSON.parse(localStorage.getItem(k)) || {});
}

export const BasketContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(basketReducer, 'zuper-basket', getFromLocalStorage);

  useEffect(() => {
    localStorage.setItem('zuper-basket', JSON.stringify(state));
  }, [state]);

  function addOneItem(product) {
    dispatch({
      type: 'add-one-item',
      payload: product
    })
  }

  function removeOneItem(product) {
    dispatch({
      type: 'remove-one-item',
      payload: product
    })
  }

  function removeProduct(id) {
    dispatch({
      type: 'remove-product',
      payload: id
    })
  }

  function empty() {
    dispatch({
      type: 'empty'
    })
  }

  return (
    <BasketContext.Provider value={{
      products: state.toJS().products,
      addOneItem: addOneItem,
      removeOneItem: removeOneItem,
      removeProduct: removeProduct,
      empty: empty,
      error: state.error,
      loading: state.loading
    }}>
      {children}
    </BasketContext.Provider>
  )
}