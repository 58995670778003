import React from 'react';

import {
  usePagination,
} from 'react-instantsearch';

function PaginationItem({ isDisabled, href, onClick, isSelected = false, ...props }) {
  if (isDisabled) {
    return (
      <li className='flex items-center justify-center select-none'>
        <span className='flex items-center justify-center bg-gray-200 text-sm rounded-full h-8 w-8' {...props} />
      </li>
    );
  }

  return (
    <li className={'select-none flex items-center justify-center rounded-full' + (isSelected ? ' border-2 border-gray-700' : '')}>
      <a
        className='flex items-center justify-center bg-ui text-sm rounded-full hover:bg-green-500 h-8 w-8 text-white'
        href={href}
        onClick={(event) => {
          event.preventDefault();
          onClick(event);
        }}
        {...props}
      />
    </li>
  );
}

export function Pagination({ onNavigate = () => { }, ...props }) {
  const {
    pages,
    currentRefinement,
    nbHits,
    nbPages,
    isFirstPage,
    isLastPage,
    canRefine,
    refine,
    createURL,
  } = usePagination(props);

  const firstPageIndex = 0;
  const previousPageIndex = currentRefinement - 1;
  const nextPageIndex = currentRefinement + 1;
  const lastPageIndex = nbPages - 1;

  return (
    <ul className="flex space-x-1">
      <PaginationItem
        isDisabled={isFirstPage}
        href={createURL(firstPageIndex)}
        onClick={() => { onNavigate(); refine(firstPageIndex) }}
      >
        ‹‹
      </PaginationItem>
      <PaginationItem
        isDisabled={isFirstPage}
        href={createURL(previousPageIndex)}
        onClick={() => { onNavigate(); refine(previousPageIndex) }}
      >
        ‹
      </PaginationItem>
      {pages.map((page) => {
        const label = page + 1;

        return (
          <PaginationItem
            key={page}
            isDisabled={false}
            aria-label={`Page ${label}`}
            href={createURL(page)}
            onClick={() => { onNavigate(); refine(page) }}
            isSelected={currentRefinement == page}
          >
            {label}
          </PaginationItem>
        );
      })}
      <PaginationItem
        isDisabled={isLastPage}
        href={createURL(nextPageIndex)}
        onClick={() => { onNavigate(); refine(nextPageIndex) }}
      >
        ›
      </PaginationItem>
      <PaginationItem
        isDisabled={isLastPage}
        href={createURL(lastPageIndex)}
        onClick={() => { onNavigate(); refine(lastPageIndex) }}
      >
        ››
      </PaginationItem>
    </ul>
  );
}
