import React, { useState, useContext, useEffect } from 'react';
import { Link, ScrollRestoration, useLoaderData } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { BasketContext } from './context-basket';
import { Logo, Footer, HorizontalPreload } from './template';
import * as Product from './product';
import * as Algolia from './algolia';

import emptyBasketImage from './assets/empty-basket.png';

function totalForProduct(p) {
  return (p?.['zuper-basket-quantity'] || 0)
    *
    (Product.effectivePrice(p) || 0);
}

function sumForProducts(products) {
  var sum = 0;
  for (const p of products) {
    sum += totalForProduct(p);
  }
  return sum;
}

export function sumItemCount(p) {
  if (!p) return 0;
  if (p.length === 0) return 0;
  return p
    .map(x => x['zuper-basket-quantity'])
    .reduce((acc, x) => acc + x);
}

function MarketList({ products, marketInfo, loading }) {
  const sum = sumForProducts(products);
  const items = sumItemCount(products);

  return (
    <div className='pt-3 pb-3'>
      <div className="h-[1px] bg-gray-400 w-full my-4"></div>
      <h3 className='text-md pt-3 pb-5'>
        <div className="flex">
          <div className="flex-none pr-2"><Product.MarketIcon market={marketInfo.market} /></div>
          <span className="font-bold pr-2">{marketInfo.alt} <span className="font-normal">({items})</span>:</span>
          <span>
            {loading ?
              <div className="pt-1">
                <HorizontalPreload short={true} />
              </div>
              :
              Product.formatPrice({ price: sum })
            }</span>
        </div>
      </h3>
      <ul>
        {products.map((product, i) => (
          <li key={product.id}><Product.MobileHit hit={product} showMarketIcon={false} basket={true} priceLoading={loading} /></li>
        ))}
      </ul>
    </div>
  );
}

function ConfirmationPopup({ msg, show, deleteFun, cancelFun }) {
  if (!show) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-40 z-40 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg z-50 max-w-sm w-full">
        <div className='justify-center p-2 text-justify'>
          {msg}
        </div>

        <div className='flex justify-center'>
          <button
            onClick={deleteFun}
            className="mx-2 w-56 mt-4 py-2 px-4 bg-red-400 hover:bg-red-500 text-white rounded transition duration-150"
          >
            Διαγραφή
          </button>
          <button
            onClick={cancelFun}
            className="mx-2 w-56 mt-4 py-2 px-4 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-150"
          >
            Όχι
          </button>
        </div>
      </div>
    </div >
  );
}

function TotalBasketPrice({loading, itemCount, overallSum, emptyFun}) {
  return (
    <div>
      <div className='pt-4 text-lg justify-center flex'>
        <span className="pr-2">Γενικό σύνολο:</span>
        {loading ?
         <div className='pt-2'>
           <HorizontalPreload short={true} />
         </div>
        :
         Product.formatPrice({ price: overallSum })}
      </div>
      <div className='pt-1 pb-4 text-sm justify-center flex text-slate-500'>{itemCount} τεμάχια</div>

      <div
        className='bottom-0 left-0 right-0 p-1 flex justify-center items-center select-none'>
        <a
          onClick={emptyFun}
          className='m-2 bg-red-400 hover:bg-red-500 px-5 py-3 rounded-full text-white text-sm cursor-pointer transition duration-150'>
          Διαγραφή όλων
        </a>
      </div>
    </div>
  );
}

function zipMap(keys, values) {
  const result = {};
  keys.forEach((key, index) => {
    result[key] = values[index];
  });
  return result;
}

export function BasketPage() {
  const basketContext = useContext(BasketContext);

  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [prices, setPrices] = useState({});
  const [mergedProducts, setMergedProducts] = useState(Object.values(basketContext.products || []));
  const [overallSum, setOverallSum] = useState(0);
  const [itemCount, setItemCount] = useState(0);

  function handleEmptyRequested() {
    setShowPopup(true);
  }

  function handleEmptyBasket() {
    setShowPopup(false);
    setPrices({});
    setMergedProducts([]);
    basketContext.empty();
  };

  function handleCancel() {
    setShowPopup(false);
  }

  useEffect(() => {
    const savedProducts = Object.values(basketContext.products || []);
    Algolia.products.getObjects(
      savedProducts.map(x => x.id),
      {
        attributesToRetrieve: [
          'id',
          'prices'
        ]
      }
    ).then(({ results }) => {
      const newPrices = zipMap(results.map(p => p.id), results.map(p => p.prices));
      setPrices(newPrices);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const savedProducts = Object.values(basketContext.products || []);
    const newProducts = [...savedProducts];

    if (Object.keys(prices).length > 0) { // TODO corner case, what if they are all discountinued?
      savedProducts.forEach((p, index) => {
        if (p.id in prices) {
          newProducts[index].discontinued = false;
          newProducts[index].prices = prices[p.id];
        } else {
          newProducts[index].discontinued = true;
          newProducts[index].prices = { 'per-item': { 'scalar': 0 } };
        }
      });
    }
    setMergedProducts(newProducts);
    setOverallSum(sumForProducts(newProducts));
    setItemCount(sumItemCount(newProducts));
  }, [basketContext, prices]);

  return (
    <div className='px-2 py-8 md:max-w-2xl md:mx-auto'>
      <Helmet>
        <title>{'zuper - Καλάθι (' + itemCount + ')'}</title>
        <meta name="description" content='Το καλάθι προϊόντων του zuper' />
      </Helmet>

      <ConfirmationPopup
        msg='Θα διαγραφούν όλα τα προϊόντα, είσαι σίγουρος;'
        show={showPopup}
        deleteFun={handleEmptyBasket}
        cancelFun={handleCancel}
      />

      <div className='pb-4 justify-center flex'>
        <Logo size='4xl' />
      </div>

      <h2>
        <span className='text-2xl font-bold justify-center flex'>Εικονικό καλάθι</span>
      </h2>

      <div className="flex justify-center pt-4">
        <p className='text-sm w-64 justify-center text-justify text-slate-500'>
          Το εικονικό καλάθι σου επιτρέπει να δεις τις συνολικές τιμές για τα προϊόντα που έχεις
          προσθέσει. Δεν παρέχει δυνατότητα αγορών.
        </p>
      </div>

      {
        (mergedProducts && mergedProducts.length > 0)
        ?
        <TotalBasketPrice
          loading={loading}
          itemCount={itemCount}
          overallSum={overallSum}
          emptyFun={handleEmptyRequested}/>
          :
          <>
            <div className="flex justify-center">
              <p className='py-8 w-2/3 text-center text-lg'>Το καλάθι σου είναι άδειο, πρόσθεσε προϊόντα για να δείς συνολικές τιμές.</p>
            </div>
            <div className="flex justify-center">
              <img className='object-contain w-56 h-56' src={emptyBasketImage} alt='Empty basket image' title='Το άδειο καλάθι' />
            </div>
          </>
      }

      {
        Object.keys(Product.marketInfo).map(key => {
          const marketProducts = mergedProducts.filter(p => p.market === key);
          if (marketProducts.length === 0) {
            return null;
          } else {
            return (<MarketList key={key} products={marketProducts} marketInfo={Product.marketInfo[key]} loading={loading} />);
          }
        })
      }

      {
        mergedProducts && mergedProducts.length > 0 && (
          <div>
            <div className="h-[1px] bg-gray-400 w-full my-4"></div>
            <TotalBasketPrice
              loading={loading}
              itemCount={itemCount}
              overallSum={overallSum}
              emptyFun={handleEmptyRequested}
            />
          </div>
        )
      }

      <div className="pt-32">
        <Footer />
      </div>
      <ScrollRestoration />
    </div>
  );
}
