import algoliasearch from 'algoliasearch';

export const searchClient = algoliasearch(
  'MG6FMAIUPF',
  '7183541eb8b4e464333d38672e70e212'
);

export const products = searchClient.initIndex('PRODUCTS');

export const indexesBySorting = {
  'price-per-unit-asc': 'PRODUCTS',
  'price-per-item-asc': 'PRODUCTS_price-per-item_ASC'
};