import React, { useContext } from 'react';

import { ScrollRestoration, useLoaderData } from 'react-router-dom';
import { Helmet } from "react-helmet";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

import { loadedProducts } from './global-state';
import * as Product from './product';
import * as Algolia from './algolia';
import { Logo, Footer, PillButton } from './template';
import { BasketContext } from './context-basket';

export async function loader({ params }) {
  const id = params.id;
  let product = loadedProducts.find(p => p.id === id);

  if (product) {
    const data = await Algolia.products.getObject(
      id,
      {
        attributesToRetrieve: [
          'description',
          'ingredients',
          'price-history',
        ]
      }
    );
    product['description'] = data['description'];
    product['ingredients'] = data['ingredients'];
    product['price-history'] = data['price-history']; //TODO handle failure
  } else {
    product = await Algolia.products.getObject( //TODO handle failure
      id,
      {
        attributesToRetrieve: [
          'id',
          'title',
          'market',
          'prices',
          'photo-filename',
          'url',
          'collected-at',
          'description',
          'ingredients',
          'price-history',
        ]
      }
    );
  }

  const todaysDate = Product.formatDateToYYYYMMDD(product['collected-at']);
  if (product && product['price-history']) {
    if (product.prices?.['per-item']?.scalar && product['price-history']) {
      product['price-history']['per-item'][todaysDate] = product.prices['per-item'].scalar;
    }
  }

  return {
    id: id,
    product: product,
  };
}

export const priceHistoryOptions = {
  responsive: true,
  locale: 'el',
  scales: {
    x: {
      grid: {
        tickColor: "#000"
      },
      ticks: {
        maxRotation: 0,
        minRotation: 0,
        autoSkipPadding: 20,
      }
    },
    y: {
      ticks: {
        callback: function (value, index, ticks) {
          return Product.formatPrice({ price: value });
        }
      }
    }
  },
  interaction: {
    intersect: false,
    mode: 'index',
  },
  plugins: {
    tooltip: {
      backgroundColor: "#000",
      displayColors: false,
      callbacks: {
        label: function (context) {
          return Product.formatPrice({ price: context.parsed.y });
        }
      }
    },
    legend: {
      display: false,
    },
  },
};

function PriceHistory({ product }) {
  if (!product || !product['price-history'] || !product['price-history']['per-item']) {
    return <div className='text-gray-500 text-sm'>Η εξέλιξη τιμής είναι προσωρινά μη διαθέσιμη.</div>;
  }
  const history = product['price-history']['per-item'];
  const dates = Object.keys(history).sort();

  const data = {
    labels: dates.map(Product.formatPriceHistoryDate),
    datasets: [
      {
        label: 'Τιμή',
        data: dates.map(key => history[key]),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  return (
    <Line options={priceHistoryOptions} data={data} />
  );
}

export function ProductPage() {
  const { product } = useLoaderData();
  const basket = useContext(BasketContext);

  return (
    <div className='max-w-3xl mx-auto p-8'>
      <Helmet>
        <title>{product.title} - zuper</title>
        <meta name="description" content={product.title} />
      </Helmet>

      <div className='pb-4 justify-center flex'>
        <Logo size='4xl' />
      </div>

      <h2 className='text-xl font-semibold'>{product.title}</h2>
      <span className='text-xs text-gray-400'>Κωδικός: {product.id}</span>

      <div className='min-h-full'>
        <div className="flex py-4">
          <div className='flex-none m-2'>
            <img className='object-cover w-36 h-36' src={Product.photoURL(product)} />
          </div>
          <div className='flex-grow pl-4'>
            <div className="flex">
              <Product.MarketIcon market={product.market} />
              <span className='pl-2'>{Product.marketTitle(product)}</span>
            </div>
            <div className='pt-4 pb-2'>
              <div className=' text-gray-800'>
                <span className='font-bold'>{Product.formatPrice({ price: product.prices?.['per-item']?.scalar })}</span>
                <span className='pl-1 text-gray-600 text-sm'>({Product.formatPrice({ price: product.prices?.['per-unit']?.scalar })}/{Product.formatUnit(product.prices?.['per-unit']?.unit)})</span>
              </div>
            </div>
            <div className='text-gray-500 text-sm pb-4'>Η τιμή ανανεώθηκε πριν {Product.formatDuration((Date.now() - product['collected-at']) / 1000)}</div>
          </div>
        </div>

        <div
          className='bottom-0 left-0 right-0 flex justify-center items-center space-x-3 py-4'>
          <a
            href={Product.marketRedirectURL(product)}
            target='_blank'>
            <PillButton>
              Δες το στο κατάστημα
            </PillButton>
          </a>
          <Product.QuantityWidget
            addFun={() => basket.addOneItem(product)}
            removeFun={() => basket.removeOneItem(product)}
            quantity={basket?.products?.[product.id]?.['zuper-basket-quantity']}
          />
        </div>

        {
          product.description &&
          <>
            <h3 className='text-lg py-4'>Περιγραφή</h3>
            <p className='text-sm'>{product.description}</p>
          </>
        }

        <h3 className='text-lg py-4'>Εξέλιξη τιμής</h3>
        <PriceHistory product={product} />

        {
          product.ingredients &&
          <>

            <h3 className='text-lg py-4'>Συστατικά</h3>
            <p className='text-sm'>{product.ingredients}</p>
          </>
        }

      </div>

      <div className="pt-32">
        <Footer />
      </div>
      <ScrollRestoration />
    </div>
  );
}
