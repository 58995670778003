import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import preloaderStampede from './assets/preloader-stampede.gif';
import preloaderStampedeShort from './assets/preloader-stampede-short.gif';

export function Logo({ size = 'xl' }) {
  return (
    <h1 className={'text-' + size + ' py-1 font-extrabold tracking-wide text-lime-600'}>
      <Link to="/">zuper</Link>
    </h1>
  );
}

export function Footer() {
  return (
    <div className='text-sm text-center text-gray-500'>
      <p className='pb-8'>&copy; 2023-2024 <a href='https://www.pixelated-noise.com' target='_blank' className='underline'>Pixelated Noise Ltd.</a> - <Link to="/faq"><span className='underline text-gray-900'>Συχνές ερωτήσεις</span></Link></p>
      <p className='pb-4'>Για οποιοδήποτε σχόλιο, email στο <a href='mailto:feedback@zuper-market.gr' className='underline'>feedback@zuper-market.gr</a></p>
    </div>
  );
}

export function HorizontalPreload({ short = false }) {
  return (
    short ?
      <img className='opacity-40' src={preloaderStampedeShort} />
      :
      <img className='opacity-40' src={preloaderStampede} />
  );
}

export function PillButton({ children, onClick, isSelected = false, ...props}) {
  return (
    <div
      className={'flex items-center text-nowrap select-none cursor-pointer justify-center bg-ui text-sm rounded-full px-4 hover:bg-green-500 h-8 text-white' + (isSelected ? ' border border-gray-700' : '')}
      onClick={onClick}
    >
      {children}
    </div>
  );
}