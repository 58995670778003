import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Highlight } from 'react-instantsearch';
import { HorizontalPreload } from './template';
import { BasketContext } from './context-basket';

import logo48ab from './assets/logo-48-ab.png';
import logo48mas from './assets/logo-48-mas.png';
import logo48mm from './assets/logo-48-mm.png';
import logo48mi from './assets/logo-48-mi.png';
import logo48skl from './assets/logo-48-skl.png';
import logo48ef from './assets/logo-48-ef.png';
import logo48pns from './assets/logo-48-pns.png';
import logo48krt from './assets/logo-48-krt.png';
import logo48bzr from './assets/logo-48-bzr.png';
import logo48thn from './assets/logo-48-thn.jpg';
import logo48xlk from './assets/logo-48-xlk.png';

export const marketInfo = {
  ab: { market: 'ab', logo: logo48ab, alt: 'ΑΒ' },
  mas: { market: 'mas', logo: logo48mas, alt: 'Μασούτης' },
  mm: { market: 'mm', logo: logo48mm, alt: 'My Market' },
  mi: { market: 'mi', logo: logo48mi, alt: 'Market In' },
  skl: { market: 'skl', logo: logo48skl, alt: 'Σκλαβενίτης' },
  ef: { market: 'ef', logo: logo48ef, alt: 'e-Fresh.gr' },
  pns: { market: 'pns', logo: logo48pns, alt: 'Panes.gr' },
  krt: { market: 'krt', logo: logo48krt, alt: 'Κρητικός' },
  bzr: { market: 'bzr', logo: logo48bzr, alt: 'Bazaar' },
  thn: { market: 'thn', logo: logo48thn, alt: 'Θανόπουλος' },
  xlk: { market: 'xlk', logo: logo48xlk, alt: 'Χαλκιαδάκης' }
};

const marketRedirectBaseURL = 'https://88ibmzhp62.execute-api.eu-west-1.amazonaws.com/prod/v1/market-redirect';
const photosURL = 'https://resources.zuper-market.gr/photos/';

import noPhoto from './assets/no-photo.png';

export function MarketIcon({ market }) {
  const m = marketInfo[market];
  if (m === undefined) {
    return null;
  }
  return (<img className='object-contain w-6 h-6' src={m?.logo} alt={m?.alt} title={m?.alt} />);
}

export function marketTitle({ market }) {
  const m = marketInfo[market];
  return m?.alt;
}

export function marketRedirectURL(hit) {
  return marketRedirectBaseURL + '?url=' + encodeURIComponent(hit.url);
}

export function internalURL(hit) {
  return `/product/${hit.id}/${hit.slug}`;
}

export function photoURL(hit) {
  if(hit?.['photo-filename'])
    return photosURL.concat(hit?.['photo-filename']);
  else
    return noPhoto;
}

let priceFormat = new Intl.NumberFormat('el-EL', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 4
});

export function formatPrice({ price }) {
  return priceFormat.format(price);
}

const unitLabels = {
  'dose': 'δόση',
  'kg': 'κιλό',
  'lt': 'λίτρο',
  'm': 'μ',
  'm2': 'τ.μ.',
  'mezoura': 'μεζούρα',
  'pair': 'ζεύγος',
  'piece': 'τεμ',
  'sheet': 'φύλλο',
  'wash': 'πλύση',
};

export function formatUnit(unit) {
  let label = unitLabels[unit];
  if (label === undefined) {
    return unit;
  } else {
    return label;
  }
}

export function formatDuration(seconds) {
  const days = Math.floor(seconds / 86400);
  seconds -= days * 86400;

  const hours = Math.floor(seconds / 3600);
  seconds -= hours * 3600;

  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  let result = '';
  if (days) result += `${days} ${days !== 1 ? 'μέρες' : 'μέρα'}`;
  else if (hours) result += `${hours} ${hours !== 1 ? 'ώρες' : 'ώρα'}`;
  else if (minutes) result += `${minutes} ${minutes !== 1 ? 'λεπτά' : 'λεπτό'}`;

  return result.trim();
}

export function formatPriceHistoryDate(dateString) {
  const date = new Date(dateString);
  const options = { day: 'numeric', month: 'short' };
  const formatter = new Intl.DateTimeFormat('el-GR', options);
  return formatter.format(date);
}

const dateFormatterYYYYMMDD = new Intl.DateTimeFormat('en-CA', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
});

export function formatDateToYYYYMMDD(date) {
  return dateFormatterYYYYMMDD.format(date);
}

export function effectivePrice(p) {
  return p?.prices?.['per-item']?.scalar;
}

function QuantityButton({ onClick, pos, children, ...props }) {
  var style = '';

  switch (pos) {
    case 'left':
      style = 'rounded-tl-full rounded-bl-full';
      break;
    case 'right':
      style = 'rounded-tr-full rounded-br-full';
      break;
    default:
      style = 'rounded-full';
  }

  return (
    <span className='flex items-center justify-center rounded-full cursor-pointer select-none'>
      <a
        className={style + ' flex items-center justify-center bg-ui hover:bg-green-400 text-sm text-white h-8 w-8 font-extrabold'}
        onClick={(event) => {
          event.preventDefault();
          onClick(event);
        }}
        {...props}
      >
        {children}
      </a>
    </span>
  );
}

export function QuantityWidget({ addFun, removeFun, quantity }) {
  return (
    <div className="flex items-stretch">
      {quantity ?
        <>
          <QuantityButton pos='left' onClick={removeFun}>-</QuantityButton>
          <div className='border-ui border-t-2 border-b-2 w-8 text-center'>{quantity}</div>
          <QuantityButton pos='right' onClick={addFun}>+</QuantityButton>
        </>
        :
        <span className='flex items-center justify-center rounded-full cursor-pointer select-none'>
          <a
            className={'rounded-full flex items-center justify-center bg-ui hover:bg-green-400 text-sm text-white h-8 px-4'}
            onClick={addFun}
          >
            <span className='hidden sm:block'>Προσθήκη</span>
            <span className='block sm:hidden'>+</span>
          </a>
        </span>
      }

    </div>
  );
}

export function PriceTag({ product, boldPerItem = true, loading }) {
  return (
    <div className=' text-gray-800'>
      {loading ?
        <HorizontalPreload />
        :
        <>
          <span className={boldPerItem ? 'font-bold' : ''}>{formatPrice({ price: product.prices?.['per-item']?.scalar })}</span>
          <span className='pl-2 text-gray-600 text-sm'>({formatPrice({ price: product.prices?.['per-unit']?.scalar })}/{formatUnit(product.prices?.['per-unit']?.unit)})</span>
        </>
      }
    </div>
  );
}

export function DesktopHit({ hit }) {
  const basket = useContext(BasketContext);

  return (
    <div className='border rounded-lg p-1 min-h-full text-center hover:border-gray-400 hover:shadow-lg grid grid-cols-1'>

      <div className="justify-self-end">
        <MarketIcon market={hit.market} />
      </div>

      <Link to={internalURL(hit)} className='flex justify-center items-center'>
        <img className='object-contain w-32 h-32' src={photoURL(hit)} />
      </Link>

      <div className='text-gray-700 text-sm md:min-h-[5rem] px-2 pt-3'>
        <Link to={internalURL(hit)} className='hover:underline'>
          <Highlight
            attribute="title"
            hit={hit}
            classNames={{
              root: 'line-clamp-3',
              highlighted: "bg-lime-200"
            }} />
        </Link>
      </div>

      <div className='px-2 pb-2'>
        <PriceTag product={hit} />
      </div>

      <div className="flex justify-center pb-2">
        <QuantityWidget
          addFun={() => basket.addOneItem(hit)}
          removeFun={() => basket.removeOneItem(hit)}
          quantity={basket?.products?.[hit.id]?.['zuper-basket-quantity']}
        />
      </div>

    </div>
  );
}

function BasketDeleteButton({ id }) {
  const basketContext = useContext(BasketContext);
  return (
    <span className='flex items-center justify-center rounded-full cursor-pointer select-none'>
      <a
        className={'rounded-full flex items-center justify-center bg-ui hover:bg-green-400 text-sm text-white h-8 px-4'}
        onClick={() => basketContext.removeProduct(id)}
      >
        <span>Διαγραφή</span>
      </a>
    </span>
  );
}

function SubTotalPrice({ quantity, perItemPrice, loading = false }) {
  return (
    <span className='pb-4 font-bold'>
      {loading ?
        <HorizontalPreload short={true} />
        :
        formatPrice({ 'price': quantity * perItemPrice })}
    </span>
  );
}

export function MobileHit({ hit, showMarketIcon = true, basket = false, priceLoading = false }) {
  const basketContext = useContext(BasketContext);
  const perItemPrice = hit.prices?.['per-item']?.scalar;
  const quantity = basketContext?.products?.[hit.id]?.['zuper-basket-quantity'] || 0;

  return (
    <div className='p-1 min-h-full hover:border-gray-400 flex items-center'>
      <div className='flex-none w-24'>
        {showMarketIcon
          &&
          <div className='absolute bg-white border border-gray-300 rounded-full shadow-xl w-8 h-8 flex items-center justify-center'>
            <MarketIcon market={hit.market} />
          </div>}
        <Link to={internalURL(hit)}>
          <img className={'object-contain w-24 h-24' + (hit.discontinued ? ' grayscale opacity-80' : '')} src={photoURL(hit)} />
        </Link>
      </div>
      <div className='flex-grow self-center pl-2'>
        <div className='text-gray-700 text-sm px-2 pt-3'>
          <Link to={internalURL(hit)} className='hover:underline'>
            {basket ?
              <span className='line-clamp-3'>{hit.title}</span>
              :
              <Highlight
                attribute='title'
                hit={hit}
                classNames={{
                  root: 'line-clamp-3',
                  highlighted: 'bg-lime-200'
                }} />
            }
          </Link>
        </div>
        <div className='p-2'>
          {hit.discontinued ?
            <span className='text-red-700 font-bold'>Ο κωδικός έχει καταργηθεί, βρείτε εναλλακτικό προϊόν.</span>
            :
            <PriceTag product={hit} boldPerItem={!basket} loading={priceLoading} />
          }
        </div>
      </div>
      <div className="flex-none flex flex-col items-center">
        {basket && !hit.discontinued &&
          <SubTotalPrice quantity={quantity} perItemPrice={perItemPrice} loading={priceLoading} />
        }
        {hit.discontinued ?
          <BasketDeleteButton id={hit.id} />
          :
          <QuantityWidget
            addFun={() => basketContext.addOneItem(hit)}
            removeFun={() => basketContext.removeOneItem(hit)}
            quantity={quantity}
          />
        }
      </div>
    </div>
  );
}
