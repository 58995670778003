import React, { useState, useEffect, useContext } from 'react';
import { setLoadedProducts } from './global-state';
import * as Basket from './page-basket';
import { BasketContext } from './context-basket';
import * as Product from './product';
import { searchClient } from './algolia';
import {
  Configure,
  RefinementList,
  Highlight,
  ClearRefinements,
  useStats,
  Hits,
  InstantSearch,
  SearchBox,
  useInstantSearch,
} from 'react-instantsearch';
import CookieConsent from "react-cookie-consent";
import { Link, ScrollRestoration } from 'react-router-dom';

import type { Hit } from 'instantsearch.js';
import { Footer, Logo, PillButton } from './template';
import { Pagination } from './pagination';
import * as Algolia from './algolia';

import './index.css'

import iconFilter from './assets/icon-filter.svg';
import iconBasket from './assets/icon-basket.svg';

import noPhoto from './assets/no-photo.png';
import noResultsImage from './assets/no-results.svg';

let countFormat = new Intl.NumberFormat('el-EL');

function enableGA4() {
  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', 'G-57DB93CDDZ');
};

function ProductCache() {
  const { results } = useInstantSearch();
  useEffect(() => {
    setLoadedProducts(results.hits);
    return () => { };
  }, [results]);
  return null;
}

function BasketButton({ basket }) {
  const itemCount = basket.products ? Basket.sumItemCount(Object.values(basket.products)) : 0;

  return (
    <Link to='/basket'>
      <div className='bg-ui hover:bg-green-500 rounded-xl text-white w-[5.5rem]'>
        <div className='flex items-center justify-center'>
          <img src={iconBasket} className='w-10 h-10 p-1 mr-1' />
          <div className='w-7'>{itemCount}</div>
        </div>
      </div>
    </Link>
  );
}

export function SearchPage() {
  const basket = useContext(BasketContext);
  const [areMobileFiltersVisible, setMobileFiltersVisible] = useState(false);
  const sortingState = useState('price-per-unit-asc');
  const [sorting, setSorting] = sortingState;

  const toggleMobileFilters = () => {
    setMobileFiltersVisible(!areMobileFiltersVisible);
  };

  return (
    <InstantSearch searchClient={searchClient} indexName={Algolia.indexesBySorting[sorting]} routing={true} insights>
      <Configure hitsPerPage={20} attributesToRetrieve={['id', 'title', 'slug', 'market', 'prices', 'photo-filename', 'url', 'collected-at']} />
      <ProductCache />
      <div>
        <CookieConsent
          buttonText='Συμφωνώ'
          enableDeclineButton={true}
          declineButtonText='Δε συμφωνώ'
          overlay={true}
          onAccept={(acceptedByScrolling) => { enableGA4(); }}
        >
          Χρησιμοποιούμε cookies για την ανάλυση της επισκεψιμότητάς μας και άλλες απαραίτητες λειτουργίες.
        </CookieConsent>

        {/* Mobile toolbox */}
        <div className='lg:hidden text-center sticky top-0 z-40 shadow-md bg-white px-2 pt-1 pb-2'>
          <div className="flex">
            <div className='w-32'></div>
            <div className='grow'>
              <Logo size='2xl' />
            </div>
            <div className='w-32 flex justify-end'>
              <BasketButton basket={basket} />
            </div>
          </div>
          <div className='pt-2'>
            <MySearchBox />
          </div>
        </div>

        {/* Mobile filter button */}
        <div
          onClick={toggleMobileFilters}
          className='lg:hidden border-gray-500 border-2 bg-white z-40 right-7 bottom-7 fixed shadow rounded-full'>
          <img
            src={iconFilter}
            className='w-10 h-10 p-2 opacity-50 hover:opacity-75' />
        </div>

        {/* Main content */}
        <div className='max-w-[126rem] p-2 xl:p-10 lg:p-5 grid grid-cols-8 mx-auto'>

          <div className={`${areMobileFiltersVisible ? 'visible' : 'hidden'}`}>
            <MobileFilterPanel close={toggleMobileFilters} sortingState={sortingState} />
          </div>

          {/* Left panel */}
          <div id='logo' className='hidden lg:block lg:col-span-2 pr-4 justify-self-end'>
            <header>
              <h1 className='text-5xl text-lime-600 font-extrabold tracking-wide'>
                <a href="/">zuper</a>
              </h1>
            </header>

            <div className="w-52 pt-5">
              <p className='text-gray-500 text-sm'>Αναζήτηστε προϊόντα από πολλαπλά super market και συγκρίνετε εύκολα τιμές!<br />Έχετε απορίες; <Link to='/faq'><span className='underline'>Πατήστε εδώ</span></Link>.</p>
            </div>

            <FilterPanel />
          </div>

          {/* Main panel */}
          <div className='col-span-8 lg:col-span-6 lg:space-y-4 space-y-2'>
            <div className='hidden lg:block'>
              <div className="flex space-x-2">
                <div className="grow">
                  <MySearchBox />
                </div>
                <BasketButton basket={basket} />
              </div>
            </div>

            <div className="lg:hidden mx-4 pb-4">
              <p className='text-gray-500 text-sm'>Αναζήτηστε προϊόντα από πολλαπλά super market ταυτόχρονα και συγκρίνετε εύκολα τιμές! Έχετε απορίες; <Link to='/faq'><span className='underline'>Πατήστε εδώ</span></Link>.</p>
            </div>

            <NoResultsBoundary fallback={<NoResults />}>

              <div className='pb-1 flex justify-center lg:hidden'>
                <Pagination padding={2} />
              </div>

              <div className='pb-1 space-x-2 hidden lg:block lg:flex'>
                <PillButton onClick={() => setSorting('price-per-unit-asc')} isSelected={sorting === 'price-per-unit-asc'}>Αύξουσα τιμή κιλού/λίτρου</PillButton>
                <PillButton onClick={() => setSorting('price-per-item-asc')} isSelected={sorting === 'price-per-item-asc'}>Αύξουσα τιμή τεμαχίου</PillButton>
                <div className='flex-grow' />
                <Pagination padding={2} />
              </div>

              <div className='sm:block hidden'>
                <Hits
                  hitComponent={Product.DesktopHit}
                  classNames={{
                    list: 'grid 2xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-3 grid-cols-1',
                    item: 'm-1'
                  }} />
              </div>

              <div className='sm:hidden block'>
                <Hits
                  hitComponent={Product.MobileHit}
                  classNames={{
                    list: 'grid grid-cols-1 divide-y'
                  }} />
              </div>

              <div className='flex justify-center'>
                <Pagination padding={2} onNavigate={() => window.scrollTo(0, 0)} />
              </div>

            </NoResultsBoundary>

            <div className='pt-32'>
              <Footer />
            </div>
          </div>
        </div>
        <ScrollRestoration />
      </div>
    </InstantSearch >
  );
}

function MobileFilterPanel({ close, sortingState }) {
  const { nbHits, processingTimeMS, query } = useStats();
  const [sorting, setSorting] = sortingState;
  const { results } = useInstantSearch();

  return (
    <div className='z-50 fixed right-0 left-0 top-0 bottom-0 bg-white overflow-y-scroll'>
      <div className='text-center sticky top-0 shadow-md bg-white'>
        <h1 className='text-md py-3'>Φίλτρα</h1>
        <button onClick={close} className='top-3 left-1 px-4 fixed'>
          x
        </button>
      </div>

      {
        !(isNoResults(results)) &&
        <div className='flex space-x-2 pt-4 justify-center'>

          <PillButton
            onClick={() => setSorting('price-per-unit-asc')}
            isSelected={sorting === 'price-per-unit-asc'}>
            Αύξουσα τιμή κιλ./λιτ.
          </PillButton>

          <PillButton
            onClick={() => setSorting('price-per-item-asc')}
            isSelected={sorting === 'price-per-item-asc'}>
            Αύξουσα τιμή τεμ.
          </PillButton>

        </div>
      }

      <div className='px-6'>
        <FilterPanel />
      </div>

      <div
        className='fixed bottom-0 left-0 right-0 p-1 bg-white shadow-[0_0_6px_rgba(0,0,0,0.25)] flex justify-center items-center'>
        <button
          onClick={close}
          className='m-2 bg-ui py-3 rounded text-white text-sm w-full'>
          {countFormat.format(nbHits)} προϊόντα
        </button>
      </div>
    </div>
  );
}

function FilterPanel() {
  const { results } = useInstantSearch();

  if (isNoResults(results)) return null;

  return (
    <div>
      <div className='pt-5 space-y-4'>
        <h3 className='font-bold tracking-wide text-gray-600'>Super market</h3>
        <MyRefinementList attribute="market-title" />

        <h3 className='font-bold tracking-wide text-gray-600'>Κατασκευαστής</h3>
        <MyRefinementList attribute="manufacturer.name" uppercase={true} />

        <h3 className='font-bold tracking-wide text-gray-600'>Κατηγορία</h3>
        <MyRefinementList attribute="zuper-categories.first.title" />
      </div>

      <div className='pt-12'>
        <MyStats />
      </div>
    </div>
  )
}

function MySearchBox() {
  return (
    <SearchBox
      placeholder=""
      searchAsYouType={false}
      autoFocus={true}
      classNames={{
        input: 'rounded-full min-w-full border-gray-300 focus:border-lime-600 pl-10',
        submit: 'hidden',
        reset: 'hidden',
        loadingIndicator: 'hidden'
      }} />
  );
}

// source: https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-display/react/#handling-no-results

function isNoResults(results): boolean {
  // The `__isArtificial` flag makes sure not to display the No Results message
  // when no hits have been returned.
  return !results.__isArtificial && results.nbHits === 0;
}

function NoResultsBoundary({ children, fallback }) {
  const { results } = useInstantSearch();

  if (isNoResults(results)) {
    return (
      <>
        {fallback}
        <div hidden>{children}</div>
      </>
    );
  }

  return children;
}

function NoResults() {
  const { indexUiState } = useInstantSearch();

  return (
    <div className='justify-center'>

      <p className='py-8 text-center text-lg'>
        {indexUiState.query ?
          <>
            <div className="flex justify-center">
              <img className='object-contain w-56 h-56' src={noResultsImage} alt='Empty basket image' title='Το άδειο καλάθι' />
            </div>
            <span class='text-gray-600'>
              <p>Δεν βρέθηκαν προϊόντα για την αναζήτηση</p>
              <p><q className='font-bold'>{indexUiState.query}</q></p>
            </span>
          </>
          :
          <span>
            Δεν υπάρχουν προϊόντα.
          </span>
        }
      </p>

      {
        indexUiState.query &&
        <div className='flex justify-center'>
          <PillButton>
            <ClearRefinements
              excludedAttributes={[]}
              translations={{
                resetButtonText: 'Καθαρισμός αναζήτησης'
              }}
            />
          </PillButton>
        </div>
      }

    </div>
  );
}

function caseInsensitiveCompare(str1, str2) {
  return str1.toLowerCase().localeCompare(str2.toLowerCase());
}

function facetCompare(x1, x2) {
  return caseInsensitiveCompare(x1.name, x2.name);
}

function MyRefinementList({ attribute, uppercase = false }) {
  return (
    <RefinementList
      attribute={attribute}
      sortBy={facetCompare} //this used to be an anonymous function used in-place, but it caused react into an infinite loop of rendering!
      showMore={true}
      showMoreLimit={30}
      classNames={{
        root: uppercase ? 'uppercase' : '',
        checkbox: 'rounded focus:ring-lime-600 text-lime-600 cursor-pointer',
        label: 'min-w-full block hover:bg-gray-100 cursor-pointer py-1 pl-2 rounded-md',
        labelText: 'text-sm text-gray-600 ml-2 pr-2 hover:text-black',
        count: "text-sm text-gray-400 ml-2 before:content-['('] after:content-[')']",
        showMore: "text-sm text-gray-600 mt-4 mb-2 ml-2 font-medium after:content-['›'] after:ml-2 after:rotate-90",
        disabledShowMore: 'hidden'
      }}
      translations={{
        showMoreButtonText({ isShowingMore }) {
          return isShowingMore ? 'Δες λιγότερα' : 'Δες περισσότερα';
        }
      }}
    />
  );
}

function MyStats() {
  const { nbHits, processingTimeMS, query } = useStats();

  return (
    <div className='text-sm text-gray-500'>Βρέθηκαν {countFormat.format(nbHits)} προιόντα σε {processingTimeMS}msec</div>
  );
}
