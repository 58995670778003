import React from 'react';
import { createRoot } from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { BasketContextProvider } from './context-basket';
import { SearchPage } from './page-search';
import { ProductPage, loader as productLoader } from './page-product';
import { BasketPage } from './page-basket';
import { FaqPage } from './page-faq';
import { ErrorPage } from "./page-error";

const router = createBrowserRouter([
  {
    path: "/",
    element: <SearchPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/product/:id/:title",
    element: <ProductPage />,
    loader: productLoader,
  },
  {
    path: "/basket",
    element: <BasketPage />,
  },
  
  {
    path: "/faq",
    element: <FaqPage />,
  }
]);

createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Helmet>
      <title>zuper - Αναζήτηση στα super market και εύκολη σύγκριση τιμών!</title>
      <meta name="description"
        content="Αναζήτηστε προϊόντα από πολλαπλά super market ταυτόχρονα και συγκρίνετε εύκολα τιμές." />
    </Helmet>
    <BasketContextProvider>
      <RouterProvider router={router} />
    </BasketContextProvider>
  </React.StrictMode>
);